<template>
  <div class="col-12">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المستخدم
                    </th>
                    <th>
                    رقم المشروع
                    </th>
                      <th>
                        رقم القطعة
                      </th>
                      <th>
                        رقم الحجز
                      </th>
                    <th>
                    التاريخ
                    </th>
                    <th>
                    الحالة
                    </th>
                    <th>
                    logs
                    </th>
                </thead>
                <tbody>
                    <tr v-for="order in orders" :key="order._id">
                        <td>
                            {{ users[order.user_id].name }}
                        </td>
                        <td>
                            {{ order.item_id }}
                        </td>
                        <td>
                            {{ order.unit_id }}
                        </td>
                        <td>
                            {{ order.order_id }}
                        </td>
                        <td>
                            {{ order.date }}
                        </td>
                        <td>
                            <span class="btn btn-primary btn-sm" style="border-radius: 0px" v-if="order.status >= 0">
                                <i class="fa fa-check"></i>
                                انشاء امر حجز
                            </span>
                            <span class="btn btn-danger btn-sm" style="border-radius: 0px" v-if="order.status >= 1">
                                <i class="fa fa-check"></i>
                                جاري الحجز
                            </span>
                            <span class="btn btn-sm btn-outline-secondary" style="border-radius: 0px" v-if="order.status < 1">
                                <i class="fa fa-times"></i>
                                جاري الحجز
                            </span>
                            <span class="btn btn-success btn-sm" style="border-radius: 0px" v-if="order.status >= 2">
                                <i class="fa fa-check"></i>
                                تم الحجز
                            </span>
                            <span class="btn btn-sm btn-outline-secondary" style="border-radius: 0px" v-if="order.status < 2">
                                <i class="fa fa-times"></i>
                                تم الحجز
                            </span>
                            <span class="btn btn-sm btn-danger" style="border-radius: 0px" v-if="order.status == 6">
                                <i class="fa fa-times"></i>
                                فشل الحجز
                            </span>
                            <div v-if="order.status == 6">
                                <textarea name="" readonly v-model="order.reason" id="" cols="30" rows="10"></textarea>
                            </div>
                        </td>
                        <td>
                            <button v-if="!order.viewlog" @click="order.viewlog = true; ff()">عرض اللوج</button>
                            <div v-if="order.viewlog">
                                {{ order.logs }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            orders: [],
            users: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/users', {
            jwt: g.user.jwt
        }).then(function(r){
            r.response.forEach(function(a){
                g.users[a._id] = a
            })
            g.getOrders()
        })
    },
    methods: {
        getOrders(){
            var g = this;
            $.post(api + '/admin/orders', {
                jwt: g.user.jwt
            }).then(function(r){
                g.orders = r.response
            })
        },
        ff(){
            this.orders = JSON.parse(JSON.stringify(this.orders))
        }
    }
}
</script>

<style>

</style>